import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/about.css"

import AboutPageBanner from "../images/about-us-page-banner.svg"

const AboutPage = () => (
  <Layout>
    <Helmet
      bodyAttributes={{
        class: "full-page about-us-page",
      }}
    />
    <SEO title="About Page" />
    <section className="section-intro">
      <div className="section--content align-items-center">
        <div className="home--left--section">
          <h1 className="home--banner-text">About us.</h1>
          <h2 className="home--banner-text-sub">
            We are a Logistics company
            <br />
            into the business of moving
            <br />
            items to various destination.
          </h2>
          <h4 className="home--banner-text-small">
            Feeling curious? Why not download our app <br /> to get started.
          </h4>
          <button className="cta cta--learn--more">Learn More</button>
        </div>
        <div className="home--right--section">
          <div className="home-delivery--illustration">
            <img src={AboutPageBanner} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section className="our-history">
      <h2 className="section--content--header center">Our History</h2>
      <div className="section--content">
        <p>
          Established in 2016, MUVIT is the first app-based platform for
          transporting goods in Lagos. Muvit was created to connect drivers and
          customers directly, and we aim to redefine the everyday logistics
          experience by providing convenient and efficient service. At the push
          of a button your goods are delivered.
        </p>

        <p>
          Since its successful November 2016 launch in Lagos, Muvit has plans to
          expand to other parts of Nigeria and indeed Africa. The Muvit network
          currently comprises of commercial vehicles and Riders and registered
          drivers. It continues to grow rapidly, and our presence will soon
          encompass additional cities on a global scale
        </p>
        <div className="mission-statement">
          <p>
            Muvit is constantly exploring the beauty of science and technology,
            leveraging it to make our daily lives easier and better.{" "}
          </p>
          <p>
            Join us on our mission to explore and expand the frontiers of
            freight. <br /> Let’s Move it! With MUVIT!
          </p>
        </div>
      </div>
    </section>
    <section className="our-growth">
      <div className="section--content">
        <h3 className="header--section">Explore our services near you</h3>
        <div className="growth-items">
          <div className="growth-item">
            <h1 className="item--val">3+</h1>
            <span className="item--label">Cities</span>
          </div>
          <div className="growth-item">
            <h1 className="item--val">1000+</h1>
            <span className="item--label">Drivers</span>
          </div>
          <div className="growth-item">
            <h1 className="item--val">170+</h1>
            <span className="item--label">Partners</span>
          </div>
        </div>
      </div>
    </section>
    <section className="our-history business-partnership">
      <h2 className="section--content--header center">Business Partnership</h2>
      <div className="section--content">
        <p>
          We are constantly exploring the beauty of science and technology, and
          we’re committed to making our daily lives easier and better. Join us
          on our mission to explore and expand the frontiers of freight.
          <strong>Please contact us at hello@muvit.com.ng</strong>
        </p>
      </div>
    </section>
  </Layout>
)

export default AboutPage
